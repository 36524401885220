<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      <el-breadcrumb-item>组织管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="oraRbox">
        <div class="orabox-left">
          <zm-tree-org
            :data="datas"
            collapsable
            @on-node-click="nodeClick"
            @on-contextmenu="onCont"
            :define-menus="define"
            :node-draggable="draggable"
            :props="ZMprops"
            :default-expand-level="1"
          ></zm-tree-org>
          <div class="hinttitle-right">
            <el-button type="primary" class="verbal brns"></el-button>
            <div class="verbal">组织</div>
            <el-button type="success" class="verbal brns"></el-button>
            <div class="verbal">岗位</div>
          </div>
        </div>
        <div class="orabox-right">
          <!-- 组织模块 -->
          <div class="right-org" v-if="itemflag">
            <div class="righttop">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item class="texts" v-for="(item, index) in this.Itemlink" :key="index">{{ item.name }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="btns">
              <el-button type="primary" plain @click="newbtnItem">新建项目</el-button>
              <!-- <el-button type="primary" plain @click="Roleclick" v-if="this.container_status === 1">员工库</el-button> -->
            </div>
            <el-table :data="posttableData" style="width: 100%">
              <el-table-column prop="name" label="项目名称" width="150"></el-table-column>
              <el-table-column prop="company_name" label="主体公司"></el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope" v-if="itemflag">
                  <el-button type="text" @click="ItemOrg(scope.row)">编辑</el-button>
                  <el-button type="text" @click="Itemdelete(scope.row)">删除</el-button>
                  <el-button type="text" @click="contractOrg(scope.row)">合同管理</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 岗位模块 -->
          <div class="right-org" v-if="postflag">
            <!-- <div class="righttop">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item class="texts" v-for="(item, index) in this.postlink" :key="index">{{ item.name }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div> -->
            <!-- <el-button type="primary" plain @click="Roleclick">岗位管理</el-button> -->

            <!-- <el-table :data="bindinguser" style="width: 80%">
              <el-table-column prop="work_no" label="工号" width="100" align="center"></el-table-column>
              <el-table-column prop="user_name" label="姓名" width="120" align="center"></el-table-column>
              <el-table-column prop="type_name" label="职级" align="center"></el-table-column>
              <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
            </el-table> -->
            <!-- <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" /> -->
          </div>
        </div>
      </div>
    </el-card>
    <!-- 新建项目弹窗 -->
    <el-dialog :title="righttitle" :visible.sync="newitem" width="30%" :before-close="newitemClose">
      <el-form :model="Nform" ref="Nform" :rules="drules">
        <el-form-item label="项目名称" label-width="150px" prop="name">
          <div class="el-select"><el-input v-model="Nform.name" placeholder="请输入项目名称"></el-input></div>
        </el-form-item>
        <el-form-item label="项目描述" label-width="150px">
          <div class="el-select"><el-input v-model="Nform.desc" placeholder="请输入项目描述"></el-input></div>
        </el-form-item>
        <el-form-item label="主体公司" label-width="150px" prop="company_id">
          <el-select v-model="Nform.company_id" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="NewitemNo">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="NewitemOk()" @click="NewitemOk(tantype)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 合同管理弹窗 -->
    <el-dialog :title="createTitle" :visible.sync="createOrgVisible" width="65%" :before-close="createOrgClose">
      <div class="dialogOrg">
        <div class="dialogOrg-left">
          <div class="hint">已绑定合同</div>
        </div>
        <div class="dialogOrg-right">
          <div class="hint">未绑定合同</div>
        </div>
      </div>
      <div class="dialogOrg">
        <div class="dialogOrg-left">
          <el-table :data="leftlogData" style="width: 100%" border>
            <el-table-column prop="name" label="合同名称" width="100" align="center"></el-table-column>
            <el-table-column prop="contract_no" label="合同编号" width="120" align="center"></el-table-column>
            <el-table-column prop="type_name" label="合同类型" align="center"></el-table-column>
            <el-table-column prop="sign_date" label="签订时间" align="center"></el-table-column>
            <el-table-column prop="contract_money" label="合同金额(元)" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.contract_money / 100 }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="Nobingding(scope.$index, scope.row)">取消绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dialogOrg-right">
          <div>
            <div class="shai">
              <el-autocomplete
                clearable
                :trigger-on-focus="false"
                placeholder="合同名称"
                v-model="hetongname"
                :fetch-suggestions="huowuvalidateCounts"
                value-key="name"
                @select="handleSelect($event, '合同名称')"
                @clear="qingchu"
              ></el-autocomplete>

              <el-date-picker
                @change="gettime"
                v-model="timeFrom"
                :picker-options="pickerOptions"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>

            <div class="shai">
              <el-select v-model="types" placeholder="合同类型" @clear="typeell" clearable>
                <el-option v-for="item in typelist" :key="item.value" :label="item.name" :value="item.value" @click.native="typsCheng"></el-option>
              </el-select>
              <el-button type="primary" round @click="qing">重置搜索与筛选</el-button>
              <el-button type="success" round @click="Newhe">新建项目合同</el-button>
            </div>

            <el-table :data="rightlogData" style="width: 100%" border>
              <el-table-column prop="name" label="合同名称" width="100" align="center"></el-table-column>
              <el-table-column prop="contract_no" label="合同编号" width="120" align="center"></el-table-column>
              <el-table-column prop="type_name" label="合同类型" align="center"></el-table-column>
              <el-table-column prop="sign_date" label="签订时间" align="center"></el-table-column>
              <el-table-column prop="contract_money" label="合同金额(元)" align="center">
                <template slot-scope="scope">
                  <div>{{ scope.row.contract_money / 100 }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="bingding(scope.$index, scope.row)">绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createOrgClose">取 消</el-button>
        <el-button type="primary" @click="ceshi">确 定</el-button>
      </span>

      <el-dialog width="30%" :title="innertitle" :visible.sync="innerVisible" append-to-body :before-close="innerClose">
        <el-form :model="Hform" ref="Hform" :rules="conRule">
          <el-form-item label="合同名称" label-width="150px" prop="name">
            <div class="el-select"><el-input v-model="Hform.name" placeholder="请输入合同名称" clearable></el-input></div>
          </el-form-item>
          <el-form-item label="合同类型" label-width="150px" prop="type">
            <el-select v-model="Hform.type" placeholder="合同类型" clearable>
              <el-option v-for="item in typelist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签订日期" label-width="150px" prop="sign_date">
            <el-date-picker v-model="Hform.sign_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>
          <el-form-item label="合同金额" label-width="150px" prop="contract_money">
            <div class="el-select"><el-input v-model="Hform.contract_money" type="Number" placeholder="请输入合同金额" clearable></el-input></div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerClose">取 消</el-button>
          <el-button type="primary" @click="contOk">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>

    <!-- 员工库绑定弹窗 -->
    <!-- <Role :roleVisible="roleVisible" :rolename="rolename" :roleId="roleId" @roleClose="roleClose"></Role> -->
  </div>
</template>

<script>
import {
  Organtreenode,
  Orgproject,
  Orgcompany,
  Orgprojectlist,
  Orgeditproject,
  Orgcontractlist,
  Orgbind,
  Orgtypelist,
  Orguserlist,
  Orgdelete,
  OrgCont
} from '../../../api/personnel.js'
import Fenye from '../../../components/Fenye.vue'
import Role from './components/role.vue'
export default {
  components: {
    Role,
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      arrs: [],
      project_ids: '',
      postlink: [],
      Itemlink: [],
      node_id: '',
      roleId: null,
      righttitle: '',
      rolename: '',
      roleVisible: false,
      types: '',
      from_sign_date: '',
      end_sign_date: '',
      timeFrom: [],
      hetongname: '',
      Dropslist: [],
      createOrgVisible: false,
      leftlogData: [],
      rightlogData: [],
      chaliang: [],
      createTitle: '',
      createOrgVisible: false,
      tantype: 'add',
      itemflag: false,
      postflag: false,
      newitem: false,
      posttableData: [], // 组织项目列表
      typelist: [],
      title: '',
      defaultList: [],
      rightname: '',
      datas: {},
      define: [{ name: '复制文本', command: 'copy' }],
      draggable: false,
      ZMprops: { id: 'id', label: 'name' },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      Nform: {
        name: '',
        company_id: '',
        node_id: '',
        desc: '',
        id: ''
      },
      options: [],
      drules: {
        name: [
          { required: true, message: '请输入组织标题', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ],
        company_id: [{ required: true, message: '请选择客户类型', trigger: 'change' }]
      },
      constID: '', // 项目id
      pageData: {
        page: 1,
        size: 10,
        count: 0
      },
      Hform: {
        company_id: '',
        type: '',
        name: '',
        sign_date: '',
        contract_money: ''
      },
      innerVisible: false,
      innertitle: '',
      conRule: {
        type: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
        // 合同名称
        name: [
          { required: true, message: '请输入合同名称', trigger: 'blur' },
          { min: 2, message: '请填写正确格式', trigger: 'blur' }
        ],
        sign_date: [{ required: true, message: '请选择签订日期', trigger: 'blur' }],
        contract_money: [{ required: true, message: '请输入合同金额', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.Organtrees()
    this.Orgcompanys()
    this.Orgtypelists()
  },
  methods: {
    // 新建项目合同按钮
    Newhe() {
      this.innerVisible = true
    },

    // 新建项目合同弹窗默认关闭
    innerClose() {
      this.innerVisible = false
      this.Hform.name = ''
      this.Hform.type = ''
      this.Hform.sign_date = ''
      this.Hform.contract_money = ''
    },

    // 新建合同提交
    contOk() {
      // console.log(2)
      this.$refs['Hform'].validate(v => {
        if (v) {
          console.log(v)
          var newdfrom = JSON.parse(JSON.stringify(this.Hform))
          newdfrom.contract_money = newdfrom.contract_money !== undefined ? newdfrom.contract_money * 100 : 0
          console.log(newdfrom)
          this.OrgConts(newdfrom)
        }
      })
    },

    /////////////////////////////////////

    ceshi() {
      this.$confirm('确定提交合同变动吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let chali = JSON.parse(this.chaliang)
          // 已绑定的id’
          let cha_id = []
          chali.forEach(item => {
            cha_id.push(item.id)
          })
          // console.log(cha_id, 'initID')
          // 修改后的id
          let left_id = []
          this.leftlogData.forEach(sos => {
            left_id.push(sos.id)
          })
          // console.log(left_id, '左侧现有ID')
          let res = [] // 解绑的数据id
          const getAbsentValues = (cha_id, left_id) => {
            res = cha_id.filter(el => {
              return !left_id.find(obj => {
                return el === obj
              })
            })
            return res
          }
          getAbsentValues(cha_id, left_id)

          // console.log(res, '解绑的数据id')

          for (let i = 0; i < left_id.length; i++) {
            for (let j = 0; j < cha_id.length; j++) {
              if (left_id[i] === cha_id[j]) {
                left_id.splice(i, 1)
              }
            }
          }
          // console.log(left_id, '新增的合同id') // 新增的合同id

          if (res.length === 0 && left_id.length === 0) {
            // console.log('没有操作')
            this.createOrgClose()
          } else {
            if (res.length !== 0) {
              let resForm = {
                project_id: this.constID,
                status: 0,
                contracts: res
              }
              this.Orgbindsres(resForm)
              // console.log('解绑有ID,走没走')
            } else {
              // console.log('解绑的数据id为空')
            }

            if (left_id.length !== 0) {
              let leftForm = {
                project_id: this.constID,
                status: 1,
                contracts: left_id
              }
              this.Orgbindsleft(leftForm)
              // console.log('绑订有ID,走没走')
            } else {
              // console.log('绑定数据id为空')
            }
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消更改'
          })
        })
    },

    // 绑定
    bingding(index, row) {
      // console.log(index, row)
      this.leftlogData.push(row)
      this.rightlogData.splice(index, 1)
    },
    // 取消绑定
    Nobingding(index, row) {
      // console.log(index, row)

      this.$confirm('确定解除合同绑定吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.arrs.push(row)
          this.rightlogData.push(row)
          this.leftlogData.splice(index, 1)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消解绑'
          })
        })
    },
    // 节点点击事件
    nodeClick(e, data) {
      console.log(data)
      this.Nform.node_id = ''
      if (data.type === 'node') {
        this.container_status = data.container_status
        this.title = '组织'
        this.Itemlink = data.link
        let arr = data.link
        let userNames = arr
          .map(item => {
            return item.name
          })
          .join('>')
        // console.log(userNames)
        this.righttitle = userNames
        this.rolename = userNames
        this.Nform.node_id = data.node_id
        this.node_id = data.node_id
        this.itemflag = true
        this.postflag = false
        this.roleId = Number(data.id)
        this.Orgprojectlists(data.node_id)
      } else {
        this.title = '岗位'
        let arr = data.link
        let userNames = arr
          .map(item => {
            return item.name
          })
          .join('>')
        // console.log(userNames)
        this.postlink = data.link
        this.itemflag = false
        this.postflag = true
        this.rolename = userNames
      }
      this.rightname = data.name
    },

    // 右键
    onCont(command) {
      // console.log(command)
    },

    // 新建项目按钮
    newbtnItem() {
      this.newitem = true
    },

    // 新建项目弹窗关闭
    newitemClose() {
      this.NewitemNo()
    },

    // 新建项目弹窗取消
    NewitemNo() {
      this.tantype = 'add'
      this.newitem = false
      // this.Nform.node_id = ''
      this.Nform.name = ''
      this.Nform.company_id = ''
      this.Nform.desc = ''
      this.Nform.id = ''
    },

    // 新建项目弹窗确认
    NewitemOk(type) {
      switch (type) {
        case 'add':
          // console.log(type, '这是新增')
          this.$refs['Nform'].validate(v => {
            // console.log(v)
            if (v) {
              // console.log(this.Nform)
              this.Orgprojects()
            }
          })
          break
        case 'edit':
          // console.log(type, '这是编辑')
          this.$refs['Nform'].validate(v => {
            // console.log(v)
            if (v) {
              // console.log(this.Nform)
              this.Orgeditprojects()
            }
          })
          break
      }
    },

    // 组织项目列表 编辑
    ItemOrg(row) {
      this.tantype = 'edit'
      // console.log(row)
      this.Nform.id = row.value
      this.newitem = true
      this.Nform.name = row.name
      this.Nform.company_id = row.company_id
      this.Nform.node_id = row.node_id
      this.Nform.desc = row.desc
    },

    // 合同管理弹窗关闭
    createOrgClose() {
      this.createOrgVisible = false
      this.hetongname = ''
      this.from_sign_date = ''
      this.end_sign_date = ''
      this.types = ''
      this.timeFrom = []
      this.leftlogData = []
      this.rightlogData = []
      this.arrs = []
    },

    // 合同管理按钮
    contractOrg(row) {
      console.log(row, '合同管理按钮')
      this.Hform.company_id = row.company_id
      this.innertitle = row.name + '>' + '新建合同'
      this.project_ids = row.value
      this.constID = row.value
      this.createTitle = '组织：' + this.rightname + ' > ' + '项目：' + row.name
      this.createOrgVisible = true
      this.Orgcontractlists()
      this.Orgbingding(row.value)
    },

    // 搜商品的数据
    huowuvalidateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },
    // 货品搜索选中
    handleSelect(e) {
      // console.log(e)
      this.Orgcontractlists(e.name)
    },

    // 合同名称搜索清除
    qingchu() {
      this.hetongname = ''
      this.Orgcontractlists()
    },

    // 时间
    gettime(timeFrom) {
      // console.log(timeFrom)
      if (timeFrom !== null) {
        this.from_sign_date = timeFrom ? timeFrom[0] : ''
        this.end_sign_date = timeFrom ? timeFrom[1] : ''
        this.Orgcontractlists()
      } else {
        this.from_sign_date = ''
        this.end_sign_date = ''
        this.Orgcontractlists()
      }
    },

    // 合同类型选中
    typsCheng() {
      this.Orgcontractlists()
    },

    // type 清空
    typeell() {
      this.types = ''
      this.Orgcontractlists()
    },

    // 重置
    qing() {
      this.from_sign_date = ''
      this.end_sign_date = ''
      this.types = ''
      this.hetongname = ''
      this.timeFrom = []
      this.Orgcontractlists()
    },

    // 岗位按钮
    Roleclick() {
      this.roleVisible = true
    },

    // 岗位添加弹窗关闭
    roleClose() {
      this.roleVisible = false
    },

    // 组织项目删除
    Itemdelete(row) {
      // console.log(row.value)

      this.$confirm('确定删除组织内项目吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Orgdeletes(row.value)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    ///////////////////请求区//////////////////

    // 新建组织内项目
    async OrgConts(newdfrom) {
      const { data } = await OrgCont({ ...newdfrom })
      console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '新建项目合同成功',
          type: 'success'
        })
        this.innerClose()
        this.Orgcontractlists()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },

    // 删除组织内项目
    async Orgdeletes(id) {
      const { data } = await Orgdelete({ id: id })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '删除组织内项目成功',
          type: 'success'
        })
        this.Orgprojectlists(this.node_id)
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },

    // 解绑组织项目合同
    async Orgbindsres(resForm) {
      const { data } = await Orgbind({ ...resForm })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '解除绑定合同成功',
          type: 'success'
        })
        this.createOrgClose()
      }
    },

    // 绑定组织项目合同
    async Orgbindsleft(leftForm) {
      const { data } = await Orgbind({ ...leftForm })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '绑定合同成功',
          type: 'success'
        })
        this.createOrgClose()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },

    // 模糊搜索
    async Drops(name, cd) {
      const { data } = await Orgcontractlist({ name, waiting: 1 })
      let date = data.data
      for (let i = 0; i < date.length; i++) {
        for (let j = 0; j < this.leftlogData.length; j++) {
          if (date[i].id === this.leftlogData[j].id) {
            date.splice(i, 1)
          }
        }
      }
      this.Dropslist = date
      cd(data.data)
    },

    // 获取组织项目合同列表(未绑定)
    async Orgcontractlists(name) {
      const { data } = await Orgcontractlist({
        name: name,
        project_id: this.project_ids,
        waiting: 1,
        from_sign_date: this.from_sign_date,
        end_sign_date: this.end_sign_date,
        type: this.types
      })
      // console.log(data)
      let date = data.data
      for (let i = 0; i < date.length; i++) {
        if (date.length !== 0) {
          for (let j = 0; j < this.leftlogData.length; j++) {
            if (date[i].id === this.leftlogData[j].id) {
              date.splice(i, 1)
            }
          }
        } else {
          console.log('空白')
        }
      }
      this.arrs.forEach(item => {
        date.push(item)
      })

      this.rightlogData = date

      // console.log(this.rightlogData)
      var obj = {}
      this.rightlogData = this.rightlogData.reduce(function (item, next) {
        // console.log(item, next)
        obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
        return item
      }, [])
      // console.log(this.rightlogData, '处理过后')

      this.leftlogData.forEach(item => {
        this.rightlogData.forEach((ele, i) => {
          if (item.id == ele.id) {
            // delete date[i]
            this.rightlogData.splice(i, 1)
          }
        })
      })
    },

    // 获取组织项目合同列表(已绑定)
    async Orgbingding(project_id) {
      const { data } = await Orgcontractlist({ project_id: project_id, waiting: 0 })
      // console.log(data)
      this.chaliang = JSON.stringify(data.data)
      this.leftlogData = data.data
    },

    // 获取组织项目合同类型列表
    async Orgtypelists() {
      const { data } = await Orgtypelist()
      // console.log(data)
      this.typelist = data.data
    },

    // 获取组织树
    async Organtrees() {
      const { data } = await Organtreenode()
      // console.log(data.data)
      if (data.data.length === 0) {
        this.datas = {}
        this.disabled = true
      } else {
        this.datas = data.data
        let Objs = data.data
        this.disabled = false
        this.getChild(Objs, '数据')
      }
    },

    // 获取主体公司
    async Orgcompanys() {
      const { data } = await Orgcompany()
      // console.log(data)
      this.options = data.data
    },

    // 获取组织项目列表
    async Orgprojectlists(node_id) {
      const { data } = await Orgprojectlist({ node_id: node_id })
      // console.log(data)
      this.posttableData = data.data
    },

    // 添加组织项目
    async Orgprojects() {
      const { data } = await Orgproject({ ...this.Nform })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '添加组织项目成功',
          type: 'success'
        })
        this.Orgprojectlists(this.Nform.node_id)
        this.NewitemNo()
        // this.Organtrees()
      } else {
        this.$confirm(`${data.message}`, {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            this.NewitemNo()
          })
          .catch(() => {
            this.NewitemNo()
          })
      }
    },

    // 编辑组织下的项目
    async Orgeditprojects() {
      const { data } = await Orgeditproject({ ...this.Nform })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '修改组织项目成功',
          type: 'success'
        })
        this.Orgprojectlists(this.Nform.node_id)
        this.NewitemNo()
        // this.Organtrees()
      } else {
        this.$confirm(`${data.message}`, {
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            this.NewitemNo()
          })
          .catch(() => {
            this.NewitemNo()
          })
      }
    },

    // 递归
    getChild(valueArr, type) {
      //   console.log(valueArr, type)
      let level = 0
      let maxLevel = 0
      const loop = (data, level) => {
        // console.log(data, 'data')
        data.children.forEach(item => {
          item.level = level
          if (level > maxLevel) {
            maxLevel = level
          }
          // console.log(item)
          if ('children' in item) {
            if (item.type === 'node') {
              // item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px', maxHeight: '50px' }
              item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px' }
            } else {
              // item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px' , maxHeight: '50px'}
              item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px' }
            }
            if (item.children.length > 0) {
              loop(item, level + 1)
            }
            if (item.children.length == 0) {
              item.children = null
            }
          }
        })
      }
      loop(valueArr, 1)
    },
  }
}
</script>

<style  lang="less">
.oraRbox {
  box-sizing: border-box;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: space-around;
  .orabox-left {
    position: relative;
    width: 67%;
    height: 100%;
    background-color: #f2f2f2;
    .hinttitle-right {
      display: flex;
      align-items: center;
      width: 23%;
      position: absolute;
      top: 0%;
      right: 0%;
      .verbal {
        margin-left: 2%;
      }
      .brns {
        margin-left: 5%;
      }
    }
  }
  .orabox-right {
    width: 33%;
    height: 100%;
    border: 1px solid #dddddd !important;
    box-shadow: 2px 2px 5px 2px #dddddd;
    .texts {
      font-size: 16px !important;
      color: #000 !important;
    }
    .righttop {
      width: 100%;
      height: 5vh;
      box-sizing: border-box;
      // border: 1px solid #000;
      padding: 7px 10px;
    }
    .right-org {
      box-sizing: border-box;
      padding: 5px 10px;
      // border: 1px solid #000;
      width: 100%;
      height: 100%;
      .btns {
        width: 100%;
        height: 6vh;
      }
    }
  }
}
.tree-org-node__text {
  padding: 0px 15px !important;
}
.tree-org-node__content {
  background-color: #409eff;
  color: white;
  border-radius: 9px !important;
}
.NoOk {
  margin-left: 10%;
}
.dialogOrg {
  width: 100%;
  // border: 1px solid #000;
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  .dialogOrg-left {
    width: 48%;
  }
  .dialogOrg-right {
    width: 48%;
    .shai {
      width: 100%;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
</style>